.contactNum {
    border: 2px solid #5C317A;
    padding: 5px 40px;
    cursor: pointer;
    white-space: nowrap;
    width: min-content;
    transition: background-color 0.5s ease;
  }
  .contactNum p {
    font-size: 16px;
    transition: color 0.3s ease;
  }
  .contactNum:hover p{
    color: white !important;
    
  }
  .contactNum:hover {
    background-color: #5C317A;
    
  }
  @media (min-width: 576px) {
    .contactNum {
      padding: 10px 30px;
    }
    .buttonSize {
      width: 50%;
    }
  }
  
  @media (min-width: 768px) {
    .buttonSize {
      width: 385px;
    }
    
  }