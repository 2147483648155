@font-face {
    font-family: 'bahnschrift';
    src: url('../../assets/fonts/BAHNSCHRIFT.TTF');
}

* {
    font-family: bahnschrift, Arial, Helvetica, sans-serif;
}
html {
    overflow-x: hidden;
}