h1 {
    width: 80%;
    color: #5B257A;
    text-shadow: 5px 3px 2px white;
    line-height: 60px;
}
#containerBackground {
    width: 85%;
}
#containerBanner {
    background-image: url("../../assets/images/banniereMobile.png");
    height: 900px; 
    background-size: cover;
    background-position: center center;
    background-origin: border-box;
    background-repeat: no-repeat;
}
.containerContact {
    border: 3px solid #11CFA1;
    transition: background-color 0.5s ease, color 0.5s ease;
    height: 120px;
}
.containerContact:hover {
    background-color: #5B257A;
    color: white;
}
.containerContact img {
    height: 70px;
    padding: 0 20px;
}
#logoSwitch {
    height: 50px !important;
    width: 115px;
}
@media (min-width: 992px) {
    #containerBanner {
        background-image: url("../../assets/images/banniere.png");
    }

}