.HeaderTable {
    background-color: #17E0AF!important;
    color: white;
}
.listForm {
    overflow: hidden;
    border: 1.5px solid black;
}
.listForm li{
    font-size: 1.2em;
}
.containerList {
    height: min-content;
}
#articleAnalytics {
    background-color: #adb5bde3;
    border: 1px solid black;
}
#articleAnalytics h3{
    font-size: 1.5em;
    color: black;
}
@media (min-width: 768px) {
    #articleAnalytics {
        width: 80%;
    }
    
}
@media (min-width: 1200px) {
    #articleAnalytics {
        width: 70%;
    }
    
}