.description {
    border: 3px solid #5C317A;
    height: 500px;
    transition: border 0.2s;

}
.description:hover {
    border: 4px solid #5C317A;
}
.description  img{
    height: 60px;
}
.description  h3{
    font-size: 25px;
}

.containerArticle {
    width: 90%;
}

.description  p{
    font-size: 14px;
    width: 90%;
}
@media (min-width: 992px) {
    
    .description  h3{
        font-size: 25px;
        line-height: 50px;
    }
    .description {
        width: 30%;
        
    }

    .containerArticle {
        width: 80%;
    }

    .description  p{
        font-size: 12px;

    }

}
@media (min-width: 1200px) {
    
    .description  p{
        font-size: 12px;
    }

}
@media (min-width: 1400px) {
    
    .description  p{
        font-size: 12px;
    }

}