#bannerImage p{
    background-color: #17e0ae;
    width: 95%;
}
@media (min-width: 992px) {
    
    
    #bannerImage p{
        background-color: #17e0aead;
       width: 70%;
    }
    #bannerImage{
        background-image: url("../../assets/images/backgroundImage.png");
        background-position: center center;
        background-origin: border-box;
        background-repeat: no-repeat;
        height: 450px; 
        
        z-index: 3;
    }

}